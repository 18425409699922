import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Box from "@mui/material/Box";
import Divider, { DividerProps } from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Skeleton from "@mui/material/Skeleton";
import { Theme } from "@mui/material/styles";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { GlobalContext } from "core/context";
import { IActionProps, IBacklinkProps, ITitleDropdownList } from "core/models";
import React from "react";
import { Link } from "react-router-dom";
import Button from "ui-kit/atoms/Button";
import HelperInfo, { HelperInfoProps } from "./HelperInfo";
import HelperTooltip, { HelperTooltipProps } from "./HelperTooltip";
import PageTitleDropdown from "./PageTitleDropdown";

const useStyles = makeStyles((theme: Theme) => ({
  backlink: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    color: theme.app.palette.action.color,
    width: "fit-content",
    border: 0,
    marginBottom: theme.spacing(3),
    "&:hover": {
      color: theme.app.palette.text.secondary,
    },
  },
  divider: {
    margin: theme.spacing(3, 0, 3.25),
  },
  header: {
    flexGrow: 1,
    overflow: "hidden",
    maxWidth: "100%",
  },
  headerComponent: {
    marginBottom: theme.spacing(4),
  },
  disabledHeaderContainer: {
    margin: 0,
  },
  disableHeaderComponent: {
    "@media (max-height: 700px)": {
      marginBottom: 0,
    },
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: 3,
    },
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      // justifyContent: "space-between",
      justifyContent: "flex-start",
      gap: 3,
    },
  },
  action: {
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(2),
    },
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(4, 2, 1, 0),
    },
  },
  elementActions: {
    display: "flex",
    alignItems: "center",
    overflowX: "auto",
    "& > *": {
      [theme.breakpoints.up("md")]: {
        marginLeft: theme.spacing(2),
      },
      [theme.breakpoints.down("md")]: {
        margin: theme.spacing(1, 2, 1, 0),
      },
    },
  },
  titleBox: {
    display: "flex",
  },
  title: {
    display: "inline-block",
    marginRight: theme.spacing(1),
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  body: {
    lineHeight: 1.55,
  },
  bodyComponent: {
    "@media (max-height: 700px)": {
      display: "none",
    },
  },
  selectedCount: {
    paddingRight: theme.spacing(3),
    marginRight: theme.spacing(1),
    borderRight: `1px solid ${theme.app.palette.shadow.secondary}`,
  },
  menuBtn: {
    padding: theme.spacing(1),
  },
  menuBtnEndIcon: {
    marginLeft: theme.spacing(1.25),
    color: theme.app.palette.action.color,
    "& > svg:first-of-type": {
      fontSize: 20,
    },
  },
}));

export interface PageHeaderTitleProps {
  title?: string | undefined | React.ReactElement;
  hiddenDivider?: boolean;
  subtitleExists?: boolean;
  titleProps?: TypographyProps;
  titleAction?: React.ReactElement;
  backlinkProps?: IBacklinkProps;
  menuProps?: ITitleDropdownList;
  disableBodyComponent?: boolean;
  disableHeader?: boolean;
  disableEditIcon?: boolean;
}

export const PageHeaderTitle = ({
  title,
  hiddenDivider,
  subtitleExists,
  titleProps,
  titleAction,
  backlinkProps,
  menuProps,
  disableBodyComponent,
  disableHeader,
  disableEditIcon,
}: PageHeaderTitleProps): React.ReactElement => {
  const classes = useStyles();
  const titleElement = (
    <Typography variant="h4" className={classes.title} {...titleProps}>
      {title || <Skeleton width={120} />}
    </Typography>
  );
  return (
    <>
      {backlinkProps && (
        <IconButton
          sx={{ mr: 3 }}
          size="small"
          // Button is a Link
          {...(!!backlinkProps.link && {
            to: backlinkProps.link,
            component: Link,
          })}
          // Button is a button
          {...(!!backlinkProps.onClick && { onClick: backlinkProps.onClick })}
        >
          <ArrowBackIcon fontSize="small" />
        </IconButton>
      )}
      {menuProps ? (
        <PageTitleDropdown
          titleElement={titleElement}
          titleText={title?.toString()}
          menuProps={menuProps}
          disableEditIcon={disableEditIcon}
        />
      ) : (
        titleElement
      )}
      {titleAction}
    </>
  );
};

PageHeaderTitle.defaultProps = {
  titleProps: {},
  backlinkProps: undefined,
  menuProps: undefined,
  disableBodyComponent: false,
  disableHeader: false,
};

export interface PageHeaderProps extends PageHeaderTitleProps {
  body?: string | React.ReactElement;
  disableBodyComponent?: boolean;
  helperProps?: HelperInfoProps;
  helperTooltip?: HelperTooltipProps;
  actionProps?: IActionProps;
  customAction?: React.ReactElement;
  filterProps?: boolean;
  selectedActions?: React.ReactElement;
  backlinkProps?: IBacklinkProps;
  hiddenDivider?: boolean;
  dividerProps?: DividerProps;
  hiddenTitle?: boolean;
  disableSelectedHeader?: boolean;
  disableHeader?: boolean;
  disableContainer?: boolean;
}

const PageHeader = ({
  backlinkProps,
  body,
  disableBodyComponent,
  title,
  titleProps,
  helperProps,
  helperTooltip,
  actionProps,
  customAction,
  filterProps,
  selectedActions,
  hiddenDivider,
  dividerProps,
  hiddenTitle,
  disableSelectedHeader,
  disableHeader,
  disableContainer,
  ...rest
}: PageHeaderProps): React.ReactElement => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const classes: any = useStyles();

  const {
    global: { selected },
  } = React.useContext(GlobalContext);

  const subtitleExists = !!body;
  const selectedActive =
    selected.length > 0 && !!selectedActions && !disableSelectedHeader;

  return (
    <>
      <Box
        className={clsx({
          [classes.headerComponent]: hiddenDivider && body,
          [classes.disableHeaderComponent]: disableBodyComponent,
          [classes.disabledHeaderContainer]: disableContainer,
        })}
      >
        <Box className={classes.container}>
          {!disableHeader && (
            <div className={classes.header}>
              {!hiddenTitle && (
                <Box
                  className={classes.titleBox}
                  sx={{
                    mb: subtitleExists && !disableBodyComponent ? 2 : 0,
                    alignItems: !!helperProps ? "baseline" : "center",
                  }}
                >
                  <PageHeaderTitle
                    title={title}
                    subtitleExists={subtitleExists}
                    hiddenDivider={hiddenDivider}
                    backlinkProps={backlinkProps}
                    titleProps={titleProps}
                    disableBodyComponent={disableBodyComponent}
                    {...rest}
                  />
                  {/* {helperProps && <HelperInfo {...helperProps} />} */}
                  {helperTooltip && <HelperTooltip {...helperTooltip} />}
                </Box>
              )}
              {!!body && disableBodyComponent ? (
                <div className={classes.bodyComponent}>{body}</div>
              ) : (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  className={classes.body}
                >
                  {body}
                </Typography>
              )}
            </div>
          )}

          <div className={classes.actions}>
            {selectedActive && (
              <div className={classes.selectedCount}>
                <Typography color="textSecondary" variant="body2">
                  {selected?.length} selected
                </Typography>
              </div>
            )}

            {selectedActive && !!selectedActions && (
              <div className={classes.elementActions}>{selectedActions}</div>
            )}
            {!selectedActive && customAction && (
              <div className={classes.elementActions}>{customAction}</div>
            )}
            {!selectedActive && actionProps && (
              <div className={classes.action}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  type="button"
                  {...(!actionProps.hiddenStartIcon && {
                    startIcon: actionProps.customStartIcon || <AddIcon />,
                  })}
                  // Button is disabled on loading
                  {...(actionProps.disabled && { disabled: true })}
                  // Button is a Link
                  {...(actionProps.link && {
                    to: actionProps.link,
                    component: Link,
                  })}
                  // Button is a button
                  {...(actionProps.onClick && {
                    onClick: actionProps.onClick,
                  })}
                >
                  {actionProps.text}
                </Button>
              </div>
            )}
          </div>
        </Box>
      </Box>
      {!hiddenDivider && (
        <Divider {...dividerProps} className={classes.divider} />
      )}
    </>
  );
};

PageHeader.defaultProps = {
  titleProps: {},
  titleAction: undefined,
  body: "",
  helperProps: undefined,
  helperTooltip: undefined,
  actionProps: undefined,
  customAction: undefined,
  filterProps: undefined,
  selectedActions: undefined,
  backlinkProps: undefined,
  menuProps: undefined,
  hiddenDivider: false,
  dividerProps: {},
  hiddenTitle: false,
  disableBodyComponent: false,
};

export default PageHeader;
