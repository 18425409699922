import { forceStyle } from "core/utils/commonHandler";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function createOverrides({
  palette,
  constants,
}: {
  palette: any;
  constants: any;
}): any {
  const MuiAppBar = {
    styleOverrides: {
      root: {
        height: constants.appbarHeight,
      },
    },
  };

  const MuiAutocomplete = {
    styleOverrides: {
      listbox: {
        maxHeight: 250,
      },
      tag: {
        margin: "1px 2px",
        "&.MuiChip-root": {
          height: 25,
        },
      },
    },
  };

  const MuiBadge = {
    styleOverrides: {
      root: {
        verticalAlign: "super",
      },
    },
  };

  const MuiButton = {
    styleOverrides: {
      root: {
        padding: "8px 18px",
        borderRadius: 6,
        textTransform: "none",
        border: "1px solid transparent",
        backgroundRepeat: "repeat-x",
        "&:disabled, &.Mui-disabled": {
          borderColor: "transparent",
          cursor: "not-allowed",
          pointerEvents: "all !important",
        },
      },
      iconSizeLarge: {
        "& > *:first-of-type": {
          fontSize: 20,
        },
      },
      iconSizeMedium: {
        "& > *:first-of-type": {
          fontSize: 18,
        },
      },
      iconSizeSmall: {
        "& > *:first-of-type": {
          fontSize: 16,
        },
      },
      sizeLarge: {
        fontSize: 16,
      },
      containedSizeMedium: {
        padding: "6px 14px",
      },
      outlinedSizeMedium: {
        padding: "6px 14px",
      },
      containedSizeSmall: {
        padding: "5px 10px",
      },
      outlinedSizeSmall: {
        padding: "5px 10px",
      },
    },
  };

  const MuiCard = {
    styleOverrides: {
      root: {
        borderRadius: 6,
        boxShadow:
          "0 4px 6px -1px rgba(0,0,0,.1), 0 2px 4px -1px rgba(0,0,0,.06)",
      },
    },
  };

  const MuiCardActions = {
    styleOverrides: {
      root: {
        padding: "12px 20px",
        borderTop: `1px solid ${palette.shadow.primary}`,
      },
    },
  };

  const MuiCardContent = {
    styleOverrides: {
      root: {
        padding: "25px 20px 15px",
      },
    },
  };

  const MuiCardHeader = {
    styleOverrides: {
      action: {
        alignSelf: "center",
        marginRight: "-4px",
      },
    },
  };

  const MuiCheckbox = {
    styleOverrides: {
      root: {
        color: palette.action.color,
      },
    },
  };

  const MuiContainer = {
    styleOverrides: {
      root: {
        paddingLeft: 12,
        paddingRight: 12,
        "@media (min-width: 600px)": {
          paddingLeft: 24,
          paddingRight: 24,
        },
        "@media (min-width: 900px)": {
          paddingLeft: 40,
          paddingRight: 40,
        },
      },
    },
  };

  const MuiDialog = {
    styleOverrides: {
      paper: {
        margin: "16px",
      },
    },
  };

  const MuiDialogActions = {
    styleOverrides: {
      root: {
        padding: "12px 24px",
      },
    },
  };

  const MuiDialogContent = {
    styleOverrides: {
      root: {
        backgroundColor: palette.background.default,
        padding: "20px 24px 16px",
        "& .MuiInputBase-root": {
          backgroundColor: "white",
        },
      },
    },
  };

  const MuiDialogContentText = {
    styleOverrides: {
      root: {
        marginBottom: 12,
        fontSize: "0.875rem",
      },
    },
  };

  const MuiDialogTitle = {
    styleOverrides: {
      root: {
        padding: "10px 12px 10px 24px",
        display: "flex",
        alignItems: "baseline",
        justifyContent: "space-between",
      },
    },
  };

  const MuiDivider = {
    styleOverrides: {
      light: {
        borderColor: palette.action.selected,
      },
    },
  };

  const MuiFormControl = {
    styleOverrides: {
      root: {
        marginBottom: 12,
      },
    },
  };

  const MuiFormLabel = {
    styleOverrides: {
      root: {
        fontSize: "0.95rem",
      },
    },
  };

  const MuiIconButton = {
    styleOverrides: {
      root: {
        color: palette.action.icon,
      },
      sizeSmall: {
        padding: "5px",
      },
    },
  };

  const MuiInputBase = {
    styleOverrides: {
      root: {
        fontSize: ".95rem",
      },
      input: {
        fontSize: ".95rem",
      },
    },
  };

  const MuiListItemButton = {
    styleOverrides: {
      root: {
        "& .MuiListItemText-primary": {
          fontWeight: 500,
        },
        "&$selected, &$selected:hover, &$focusVisible": {
          backgroundColor: palette.action.selected,
          borderColor: "transparent",
          color: palette.text.primary,
          "& .MuiListItemText-primary": {
            fontWeight: 700,
          },
        },
      },
      selected: {},
      focusVisible: {},
      button: {
        "&:hover": {
          backgroundColor: palette.action.hoverBackground,
          borderColor: "transparent",
        },
      },
      gutters: {
        paddingLeft: 20,
        paddingRight: 20,
      },
      divider: {
        borderColor: palette.shadow.primary,
      },
    },
  };

  const MuiListItem = {
    styleOverrides: {
      root: {
        "& .MuiListItemText-primary": {
          fontWeight: 500,
        },
        "&$selected, &$selected:hover, &$focusVisible": {
          backgroundColor: palette.action.selected,
          borderColor: "transparent",
          color: palette.text.primary,
          "& .MuiListItemText-primary": {
            fontWeight: 700,
          },
        },
      },
      selected: {},
      focusVisible: {},
      button: {
        "&:hover": {
          backgroundColor: palette.action.hoverBackground,
          borderColor: "transparent",
        },
      },
      gutters: {
        paddingLeft: 20,
        paddingRight: 20,
      },
      divider: {
        borderColor: palette.shadow.primary,
      },
    },
  };

  const MuiListItemAvatar = {
    styleOverrides: {
      root: {
        minWidth: 54,
      },
    },
  };

  const MuiMenu = {
    styleOverrides: {
      paper: {
        maxHeight: 300,
      },
    },
  };

  const MuiMenuItem = {
    styleOverrides: {
      root: {
        fontSize: ".875rem",
        color: palette.action.main,
        "&:hover": {
          color: palette.text.primary,
          backgroundColor: palette.action.hoverBackground,
        },
      },
    },
  };

  const MuiPaper = {
    styleOverrides: {
      root: {
        // boxShadow: "none",
      },
      outlined: {
        border: 0,
        borderRadius: 6,
        boxShadow:
          "rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(64, 68, 82, 0.16) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px",
        "&:hover": {
          boxShadow:
            "rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(64, 68, 82, 0.16) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(64, 68, 82, 0.08) 0px 3px 9px 0px, rgba(64, 68, 82, 0.08) 0px 2px 5px 0px",
        },
      },
    },
  };

  const MuiSlider = {
    styleOverrides: {
      track: {
        height: 4,
      },
      thumb: {
        height: 12,
        width: 12,
        "&.Mui-disabled": {
          display: "none",
        },
      },
      valueLabel: {
        fontSize: 13,
        padding: 0,
        backgroundColor: "unset",
        color: palette.text.primary,
        "&:before": {
          display: "none",
        },
        "& *": {
          background: "transparent",
          color: palette.mode === "dark" ? "#fff" : "#000",
        },
      },
      rail: {
        height: 4,
        opacity: 0.5,
        backgroundColor: "#bfbfbf",
      },
      mark: {
        color: "transparent",
      },
      markLabel: {
        fontSize: 13,
        fontWeight: "normal",
        top: -20,
      },
      root: {
        "&.Mui-disabled": {
          "& .MuiSlider-track": {
            display: "none",
          },
        },
      },
    },
  };

  const MuiSpeedDialAction = {
    styleOverrides: {
      staticTooltipLabel: {
        fontSize: "0.8rem",
        whiteSpace: "nowrap",
        padding: "2px 10px",
      },
    },
  };

  const MuiStep = {
    styleOverrides: {
      root: {
        paddingLeft: 6,
        paddingRight: 6,
      },
    },
  };

  const MuiStepConnector = {
    styleOverrides: {
      root: {
        zIndex: 1,
      },
    },
  };

  const MuiTableCell = {
    styleOverrides: {
      root: {
        padding: "0px 14px",
      },
      head: {
        textTransform: "uppercase",
        fontSize: 11,
        fontWeight: 500,
        backgroundColor: "white",
        borderBottom: `1px solid ${palette.shadow.secondary}`,
        "&:first-of-type": {
          paddingLeft: 5,
        },
        "&:last-of-type": {
          paddingRight: 5,
        },
      },
      body: {
        color: palette.text.secondary,
        borderBottom: `1px solid ${palette.shadow.secondary}`,
        "&:first-of-type": {
          paddingLeft: 5,
        },
        "&:last-of-type": {
          paddingRight: 5,
        },
      },
      paddingCheckbox: {
        width: 48,
        padding: "0 0 0 6px",
      },
    },
  };

  const MuiTablePagination = {
    styleOverrides: {
      root: {
        zIndex: 3,
        backgroundColor: "white",
      },
      spacer: {
        display: "none",
      },
      toolbar: {
        minHeight: "56px !important",
        padding: "0px !important",
      },
    },
  };

  const MuiTableRow = {
    styleOverrides: {
      root: {
        height: 40,
        "&.MuiTableRow-hover": {
          "&:hover": {
            backgroundColor: palette.action.hoverBackground,
          },
        },
        "&.Mui-selected": {
          backgroundColor: palette.action.selectedBackground,
          // "& > td:first-of-type": {
          //   borderLeft: `2px solid ${palette.primary.main}`,
          // },
        },
      },

      head: {
        height: 40,
      },
    },
  };

  const MuiTabs = {
    styleOverrides: {
      root: {
        minHeight: 30,
        borderBottom: `1px solid ${palette.shadow.secondary}`,
      },
      indicator: {
        backgroundColor: palette.primary.main,
      },
    },
  };

  const MuiTab = {
    styleOverrides: {
      root: {
        padding: 0,
        marginRight: 28,
        minHeight: 42,
        minWidth: "0px !important",
        "&:hover": {
          "& $wrapper": {
            color: palette.text.textSecondary,
          },
        },
        "&$selected": {
          "& $wrapper": {
            color: `${palette.primary.main} !important`,
          },
        },
      },
      selected: {},
      textColorInherit: {
        opacity: 1,
      },
      wrapper: {
        textTransform: "capitalize",
        fontSize: 14,
        fontWeight: 500,
        color: palette.action.color,
      },
    },
  };

  const MuiTextField = {
    styleOverrides: {
      root: {
        "& .MuiInputLabel-root": {
          fontSize: "0.9rem",
        },
        "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
          transform: "translate(14px, 6px) scale(.7)",
        },
        "& .MuiInputLabel-outlined": {
          color: palette.action.color,
          "& span": {
            display: "none",
          },
        },
        "& .MuiOutlinedInput-multiline": {
          padding: "22px 14px 9px",
        },
        "& .MuiOutlinedInput-root": {
          "& .MuiOutlinedInput-input": {
            fontSize: "0.9rem",
            height: "auto",
          },
          "&.MuiAutocomplete-inputRoot": {
            padding: 10,
          },
          "& .MuiOutlinedInput-input.MuiOutlinedInput-inputAdornedStart": {
            padding: "15.5px 10px",
          },
          "& .MuiOutlinedInput-input.MuiInputBase-inputAdornedStart": {
            padding: "13px 10px 13px 0px",
          },
          "& .MuiOutlinedInput-input:not(.MuiInputBase-inputAdornedStart)": {
            padding: "21px 14px 10px",
            "&[placeholder]": {
              padding: "15.5px 14px",
            },
            "&.Mui-disabled": {
              backgroundColor: palette.action.disabledBackground,
            },
          },
          "& .MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd": {
            "&[placeholder]": {
              padding: "21px 14px 10px",
            },
          },
          "& .MuiAutocomplete-input": {
            padding: "13px 4px 0px !important",
          },
          "& fieldset": {
            top: 0,
            "& legend": {
              display: "none",
            },
          },
          "&:hover fieldset": {
            borderColor: "#D2D6DC",
          },
          "&.Mui-focused fieldset": {
            borderColor: "#76a9fa",
            borderWidth: 1,
            boxShadow: "0 0 0 3px rgba(118,169,250,.45)",
          },
          "&.Mui-TextField-filter": {
            padding: 0,
            "& .MuiAutocomplete-input": {
              padding: "6px 10px !important",
              fontSize: "0.85rem",
            },
          },
        },
        "&.multiple": {
          "& .MuiOutlinedInput-root": {
            "&.MuiAutocomplete-inputRoot:not(.Mui-TextField-filter)": {
              paddingTop: "21px !important",
              paddingBottom: "4.80px !important",
            },
            "& .MuiAutocomplete-input": {
              padding: "2.6px !important",
            },
            "&.MuiAutocomplete-inputRoot.Mui-TextField-filter": {
              padding: "6px 10px !important",
            },
          },
        },
      },
    },
  };

  const MuiTimelineConnector = {
    styleOverrides: {
      root: {
        width: 1.5,
        minHeight: 26,
      },
    },
  };

  const MuiTimelineItem = {
    styleOverrides: {
      root: {
        minHeight: "auto",
      },
      missingOppositeContent: {
        "&:before": {
          padding: 0,
          flex: "inherit",
        },
      },
    },
  };

  const MuiToggleButton = {
    styleOverrides: {
      root: {
        padding: "11px 14px",
      },
    },
  };

  const MuiToolbar = {
    styleOverrides: {
      root: {
        justifyContent: "space-between",
      },
      gutters: {
        padding: 0,
      },
    },
  };

  const MuiTooltip = {
    styleOverrides: {
      tooltip: {
        fontSize: "0.8rem",
        fontWeight: 500,
        lineHeight: 1.35,
        backgroundColor: "#434343",
        borderRadius: 6,
        margin: forceStyle("5px"),
        padding: "3px 8px",
        border: "none",
        filter:
          "drop-shadow(0 3px 10px rgba(0,0,0,0.15)) drop-shadow(0 1px 2px rgba(0,0,0,0.1))",
      },
      arrow: {
        color: "#434343",
      },
    },
  };

  const MuiTypography = {
    styleOverrides: {
      root: {
        "&.Mui-selected.PrivateTimePickerToolbar-ampmLabel": {
          backgroundColor: palette.primary.main,
          color: "white",
          borderRadius: 50,
          padding: 4,
          fontSize: 14,
        },
      },
    },
  };

  return {
    MuiAppBar,
    MuiAutocomplete,
    MuiBadge,
    MuiButton,
    MuiCard,
    MuiCardActions,
    MuiCardContent,
    MuiCardHeader,
    MuiCheckbox,
    MuiContainer,
    MuiDialog,
    MuiDialogActions,
    MuiDialogContent,
    MuiDialogContentText,
    MuiDialogTitle,
    MuiDivider,
    MuiFormControl,
    MuiFormLabel,
    MuiIconButton,
    MuiInputBase,
    MuiListItem,
    MuiListItemButton,
    MuiListItemAvatar,
    MuiMenu,
    MuiMenuItem,
    MuiPaper,
    MuiSlider,
    MuiSpeedDialAction,
    MuiStep,
    MuiStepConnector,
    MuiTableCell,
    MuiTablePagination,
    MuiTableRow,
    MuiTabs,
    MuiTab,
    MuiTextField,
    MuiTimelineConnector,
    MuiTimelineItem,
    MuiToggleButton,
    MuiToolbar,
    MuiTooltip,
    MuiTypography,
  };
}
