import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import get from "lodash/get";
import PaymentUtils from "modules/Payment/utils";
import { ITeamInvite } from "modules/Team/models";
import TeamService from "modules/Team/services";
import { UserContext } from "modules/User/context";
import React from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import DialogHeader from "ui-kit/components/DialogHeader";
import PaymentBox from "../PaymentBox";

interface PaymentDialogProps {
  skipPaymentDialog?: boolean;
  accountId: number | undefined;
  accountHasProxy: boolean;
  setAccountId: (accountId: number | undefined) => void;
}

const PaymentDialog = ({
  accountId,
  accountHasProxy,
  setAccountId,
  skipPaymentDialog,
}: PaymentDialogProps): React.ReactElement | null => {
  const handleClose = () => {
    setAccountId(undefined);
  };

  const location = useLocation();

  const { user } = React.useContext(UserContext);

  const { data: dataAccountInvites } = useQuery(
    ["invites", accountId],
    async () => {
      try {
        const response = await TeamService.fetchAccountTeamInvites(
          Number(accountId)
        );
        return response.data;
      } catch (err) {
        throw new Error(String(err));
      }
    },
    {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!accountId,
    }
  );

  if (!accountId) {
    return null;
  }

  const accountInvite: ITeamInvite = get(dataAccountInvites, "results[0]");

  return (
    <Dialog
      open={!!accountId}
      onClose={handleClose}
      maxWidth="md"
      keepMounted
      PaperProps={{ sx: { width: "100%" } }}
    >
      <DialogHeader
        title={
          user?.id === accountInvite?.inviter?.id && accountInvite
            ? `${accountInvite.inviter.name} asked you to add a plan for their account (${accountInvite.account.login})`
            : ""
        }
        onHandleClose={handleClose}
      />
      <Box sx={{ mb: 8, px: 8, pt: 2 }}>
        <PaymentBox
          accountId={accountId}
          checkoutUrls={PaymentUtils.getUpgradeUrls(
            accountId,
            accountHasProxy,
            location.pathname
          )}
          skipPaymentDialog={skipPaymentDialog}
        />
      </Box>
    </Dialog>
  );
};

export default PaymentDialog;
