import React from "react";
import { makeStyles } from "@mui/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import WebhookIcon from "@mui/icons-material/Webhook";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import LockClockIcon from "@mui/icons-material/LockClock";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import ThermostatOutlinedIcon from "@mui/icons-material/ThermostatOutlined";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import PlagiarismOutlinedIcon from "@mui/icons-material/PlagiarismOutlined";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { Theme } from "@mui/material/styles";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    display: "flex",
    justifyContent: "space-around",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  item: {
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(1.5, 4),
    },
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1, 0),
    },
  },
  iconItem: {
    minWidth: 30,
  },
  icon: {
    fontSize: 18,
  },
  text: {
    fontSize: "0.825rem",
  },
}));

interface IFeature {
  text: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: React.FunctionComponent<any>;
}

const createFeature = (text: IFeature["text"], icon: IFeature["icon"]) => ({
  text,
  icon,
});

const FEATURES_1 = [
  createFeature(`<b>Unlimited</b> connection requests`, PersonAddIcon),
  createFeature(`<b>Unlimited</b> number of campaigns`, ScheduleSendIcon),
  createFeature(
    "<b>Sales Navigator</b> & <b>Recruiter</b> compatible",
    LinkedInIcon
  ),
  createFeature(
    "Import <b>custom lists</b> from Google Sheet",
    PlagiarismOutlinedIcon
  ),
  createFeature(
    "<b>Personalized</b> invitations and messages",
    MessageOutlinedIcon
  ),
  createFeature("<b>Smart limits</b> and account security", LockClockIcon),
  createFeature(`<b>Auto-withdraw</b> pending invitations`, UnsubscribeIcon),
];

const FEATURES_2 = [
  createFeature(`Up to <b>2500</b> results in search`, PersonSearchIcon),
  createFeature(`Smart <b>2-way inbox</b>`, QuestionAnswerOutlinedIcon),
  createFeature("Automated profile visits", AccountBoxIcon),
  createFeature("Manage <b>multiple accounts</b>", PeopleAltOutlinedIcon),
  createFeature("Account <b>warmup</b>", ThermostatOutlinedIcon),
  createFeature("<b>Integrations</b> through webhooks", WebhookIcon),
  createFeature("Campaign <b>A/B</b> testing", CallSplitIcon),
];

const FeatureColumn = ({ list }: { list: IFeature[] }) => {
  const classes = useStyles();
  return (
    <div>
      {list.map(({ text, icon: IconComponent }, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <ListItem key={index} className={classes.item}>
          <ListItemIcon className={classes.iconItem}>
            <IconComponent className={classes.icon} color="secondary" />
          </ListItemIcon>
          <ListItemText
            // eslint-disable-next-line react/no-danger
            secondary={<div dangerouslySetInnerHTML={{ __html: text }} />}
            secondaryTypographyProps={{
              className: classes.text,
              color: "textSecondary",
              component: "span",
            }}
          />
        </ListItem>
      ))}
    </div>
  );
};

const FeatureList = (): React.ReactElement => {
  const classes = useStyles();

  return (
    <List className={classes.list}>
      <FeatureColumn list={FEATURES_1} />
      <FeatureColumn list={FEATURES_2} />
    </List>
  );
};

export default FeatureList;
