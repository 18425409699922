import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import ElementField from "core/components/ElementField";
import SettingsItem from "core/components/SettingsItem";
import SwitchField from "core/components/SwitchField";
import DateConstant from "core/constants/DateConstant";
import { createComponents } from "core/utils/componentsHandler";
import { UserContext } from "modules/User/context";
import { IPasswordChange, IUser } from "modules/User/models";
import { UserActionType } from "modules/User/reducers";
import UserService from "modules/User/services";
import UserValidation from "modules/User/validations";
import React from "react";
import { useQuery } from "react-query";
import PageHeader from "ui-kit/components/PageHeader";
import PasswordField from "ui-kit/components/PasswordField";

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    display: "block",
  },
  inputForm: {
    marginBottom: theme.spacing(2),
  },
}));

const InputProps = {
  sx: { width: 300 },
};

const UserDetail = (): React.ReactElement => {
  const [reset, doReset] = React.useState(false);
  const { user, dispatch } = React.useContext(UserContext);

  console.log(user);
  const classes = useStyles();

  // Update user in the local storage
  const { data } = useQuery(
    ["user"],
    async () => {
      try {
        const response = await UserService.fetchProfile(Number(user?.id));
        return response.data;
      } catch (err) {
        throw new Error(String(err));
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: !user.anonymous && !!user?.id,
    }
  );

  const firstUpdate = React.useRef(true);
  React.useEffect(() => {
    if (data) {
      if (!firstUpdate.current) {
        return;
      }
      dispatch({
        type: UserActionType.SET_USER_DATA,
        payload: data,
      });
      doReset(true);
      firstUpdate.current = false;
    }
  }, [dispatch, data]);

  return (
    <>
      <PageHeader
        title="User settings"
        body="Here you can edit your general user settings."
      />
      <>
        {/* <SettingsItem
          FormProps={{
            func: UserService.updateEmailAddress,
            format: (d: IEmailFormInput) => d,
            defaultValues: {
              email: "",
            },
            resetForm: true,
            successMsg: (_, d: IEmailFormInput) =>
              snackbarHandler.success(
                `Confirmation email to verify sent to ${d.email}.`
              ),
          }}
          PaperHeaderProps={{
            title: "Login",
            body: (
              <>
                Update your email address: <b>{user.email}</b>
              </>
            ),
          }}
          components={[
            createComponents(ElementField, "email", {
              InputProps: {
                label: "New email address",
                InputProps,
              },
            }),
          ]}
        /> */}

        <SettingsItem
          FormProps={{
            func: UserService.updateProfile,
            id: user.id,
            format: (d: IUser) => d,
            onSuccess: (data) => {
              dispatch({
                type: UserActionType.SET_USER_DATA,
                payload: data,
              });
            },
            defaultValues: {
              first_name: user.first_name,
              last_name: user.last_name,
            },
            resetHandler: reset,
            schema: UserValidation.updateNameSchema(),
          }}
          PaperHeaderProps={{
            title: "User name",
            body: "Edit your user name.",
          }}
          components={[
            createComponents(ElementField, "first_name", {
              InputProps: {
                label: "First name",
                InputProps,
                className: classes.input,
              },
            }),
            createComponents(ElementField, "last_name", {
              InputProps: {
                label: "Last name",
                InputProps,
                className: classes.input,
              },
            }),
          ]}
        />

        <SettingsItem
          FormProps={{
            func: UserService.changePassword,
            schema: UserValidation.resetPasswordSchema(),
            format: (d: IPasswordChange) => d,
            defaultValues: {
              new_password1: "",
              new_password2: "",
            },
            resetForm: true,
          }}
          PaperHeaderProps={{
            title: "Password",
            body: "Change your user password.",
          }}
          components={[
            createComponents(ElementField, "new_password1", {
              InputProps: {
                label: "New password",
                type: "password",
                className: classes.input,
                InputProps,
                autoComplete: "new-password",
              },
              component: PasswordField,
            }),
            createComponents(ElementField, "new_password2", {
              InputProps: {
                label: "Confirm new password",
                type: "password",
                className: classes.input,
                InputProps,
                autoComplete: "new-password",
              },
              component: PasswordField,
            }),
          ]}
        />
        <SettingsItem
          FormProps={{
            func: UserService.updateProfile,
            id: user.id,
            format: ({ locale_24 }: { locale_24: boolean }) => ({
              frontend_state: { locale_24 },
            }),
            onSuccess: (data) => {
              dispatch({
                type: UserActionType.SET_USER_DATA,
                payload: data,
              });

              // eslint-disable-next-line no-restricted-globals
              window?.location?.reload();
            },
            defaultValues: { locale_24: DateConstant.LOCALE_24H },
          }}
          PaperHeaderProps={{
            title: "Time format",
            body: "Use 12 or 24-hour format.",
          }}
          components={[
            createComponents(ElementField, "locale_24", {
              InputProps: {
                label: ["24-hour time", "12-hour time"],
                sx: { marginBottom: 3, marginLeft: 1 },
              },
              component: SwitchField,
            }),
          ]}
        />
        <SettingsItem
          FormProps={{
            func: UserService.updateProfile,
            id: user.id,
            format: ({ language_cz }: { language_cz: boolean }) => ({
              frontend_state: { language_cz },
            }),
            onSuccess: (data) => {
              dispatch({
                type: UserActionType.SET_USER_DATA,
                payload: data,
              });

              // eslint-disable-next-line no-restricted-globals
              window?.location?.reload();
            },
            defaultValues: {
              language_cz: user.frontend_state.language_cz || false,
            },
          }}
          PaperHeaderProps={{
            title: "Languages",
            body: `Enable usage of special placeholders for supported languages.`,
          }}
          components={[
            createComponents(ElementField, "language_cz", {
              InputProps: {
                label: "Czech (Čeština)",
                sx: { marginBottom: 3, marginLeft: 1 },
              },
              component: SwitchField,
            }),
          ]}
          // Last item in the list
          hiddenDivider
        />
      </>
    </>
  );
};

export default UserDetail;
