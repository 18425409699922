import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Box, Container, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/styles";
import ConfigConstant from "core/constants/ConfigConstant";
import { DiffInterval } from "core/models";
import {
  dayjs,
  differenceByInterval,
  parseStripeDate,
} from "core/utils/dateHandler";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { stripePromise } from "core/utils/paymentHandler";
import { first, get } from "lodash";
import { AccountContext } from "modules/Account/context";
import { AccountQuery } from "modules/Account/models";
import {
  Currencies,
  ICustomer,
  ISubscription,
  RecurringInterval,
  SubscriptionStatus,
} from "modules/Payment/models";
import PaymentService from "modules/Payment/services";
import PaymentUtils from "modules/Payment/utils";
import React from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router-dom";

const TrialBar = (): React.ReactElement | null => {
  const theme = useTheme();
  const history = useHistory();

  const {
    account: { id: accountId, proxy_id, person: isInitialSync },
  } = React.useContext(AccountContext);

  const [customerCurrency, setCustomerCurrency] = React.useState<Currencies>(
    Currencies.usd
  );
  const [priceId, setPriceId] = React.useState<string>("");

  const [subscription, setSubscription] = React.useState<
    ISubscription | undefined
  >(undefined);

  const { data } = useQuery(
    [AccountQuery.account_subscription, accountId],
    async () => {
      try {
        const response = await PaymentService.fetchAccountSubscription(
          accountId
        );
        return response.data;
      } catch (err) {
        throw new Error(String(err));
      }
    },
    {
      // If synced
      enabled: !!accountId,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  React.useEffect(() => {
    if (data?.subscriptions) {
      const newSubscription = data.subscriptions.filter(
        (x) => !x.canceled_at && !x.deleted
      );
      setSubscription(first(newSubscription));
    }

    if (data?.subscriptions === undefined && subscription !== undefined) {
      setSubscription(undefined);
    }
  }, [data?.subscriptions, subscription]);

  // Set default price
  const fetchPrices = async () => {
    try {
      const { data } = await PaymentService.fetchPrices();
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data: dataPrices } = useQuery(
    ["prices", "all"],
    () => fetchPrices(),
    {
      // If synced
      enabled: !!accountId,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const fetchCustomers = async () => {
    try {
      const { data } = await PaymentService.fetchCustomers();
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data: dataCustomers } = useQuery(
    ["customers"],
    () => fetchCustomers(),
    {
      // If synced
      enabled: !!accountId,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const existingCustomer: ICustomer | undefined = get(
    dataCustomers,
    "results[0]"
  );

  React.useEffect(() => {
    if (existingCustomer?.currency) {
      setCustomerCurrency(existingCustomer.currency);
      return;
    }

    const subscriptionCurrency = get(data, "subscriptions[0].data.currency");
    if (subscriptionCurrency) {
      setCustomerCurrency(subscriptionCurrency);
    }
  }, [customerCurrency, data, existingCustomer]);

  React.useEffect(() => {
    if (!!dataPrices?.count) {
      const newPriceId = dataPrices?.results.find(
        (p) =>
          p.data.nickname === ConfigConstant.PRICE.FULL.NICKNAME &&
          p.data.currency === customerCurrency &&
          p.data.recurring.interval === RecurringInterval.month
      );

      // Set default priceID
      if (!!newPriceId) {
        setPriceId(newPriceId.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPrices, customerCurrency]);

  const mutateOnClick = useMutation(
    () =>
      PaymentService.createSubscriptionSession({
        price: priceId,
        account: accountId,
        ...PaymentUtils.getUpgradeUrls(
          accountId,
          !!proxy_id,
          history.location.pathname
        ),
      }),
    {
      onSuccess: async (response) => {
        const stripe = await stripePromise;
        stripe?.redirectToCheckout({
          sessionId: response.data.id,
        });
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  if (
    !subscription ||
    subscription.status !== SubscriptionStatus.trialing ||
    // !isInitialSync || // Don't show trial banner if not finished initial sync
    !customerCurrency || // Don't show trial banner if no currency
    !priceId // Don't show trial banner if no priceId
  ) {
    return null;
  }

  return (
    <Box
      sx={{
        backgroundColor: theme.app.palette.shield,
        padding: theme.spacing(2, 0),
        zIndex: 2,
        flexGrow: 1,
      }}
    >
      <Container maxWidth="sm" sx={{ height: "100%" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "100%",
            gap: 3,
          }}
          data-cy="trial-menu"
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              height: "100%",
              gap: 2,
            }}
          >
            <AccessTimeIcon
              sx={{
                color: theme.palette.common.white,
                fontSize: 21,
                fontWeight: 500,
                WebkitFontSmoothing: "subpixel-antialiased",
              }}
            />
            <Typography
              variant="body2"
              color="common.white"
              sx={{
                fontWeight: 600,
                WebkitFontSmoothing: "subpixel-antialiased",
              }}
            >
              {differenceByInterval(
                parseStripeDate(subscription?.data?.trial_end),
                dayjs(),
                DiffInterval.day
              )}{" "}
              in your trial.
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              height: "100%",
              gap: 2,
            }}
          >
            <Button
              sx={{
                border: `1.5px solid ${theme.palette.common.white}`,
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.common.white,
                fontWeight: 600,
                WebkitFontSmoothing: "subpixel-antialiased",
                "&:hover": {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.app.palette.shadow.secondary,
                  borderColor: theme.app.palette.shadow.secondary,
                },
              }}
              size="small"
              variant="contained"
              onClick={() => mutateOnClick.mutate()}
            >
              Upgrade now
            </Button>

            {/* <Button
              component="a"
              href={ConfigConstant.CALENDY_URL}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                color: theme.palette.common.white,
                border: `1.5px solid ${theme.palette.common.white}`,
                fontWeight: 600,
                WebkitFontSmoothing: "subpixel-antialiased",
                "&:hover": {
                  border: `1.5px solid ${theme.palette.common.white}`,
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.background.default,
                },
              }}
              size="small"
              variant="outlined"
            >
              Book a demo
            </Button> */}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default TrialBar;
