import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Lock from "@mui/icons-material/Lock";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import { lighten, Theme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { AccountQuery } from "modules/Account/models";
import clsx from "clsx";
import ConfigConstant from "core/constants/ConfigConstant";
import ButtonGroupFilter from "core/filters/atoms/ButtonGroupFilter";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import {
  hasActiveSubscription,
  isUserTrialAvailable,
  stripePromise,
} from "core/utils/paymentHandler";
import get from "lodash/get";
import FeatureList from "modules/Account/components/FeatureList";
import {
  Currencies,
  CurrencySign,
  ICheckoutSessionFormValues,
  ICheckoutUrls,
  ICustomer,
  ISubscriptions,
  RecurringInterval,
} from "modules/Payment/models";
import PaymentService from "modules/Payment/services";
import PaymentUtils from "modules/Payment/utils";
import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Button from "ui-kit/atoms/Button";
import Loader from "ui-kit/components/Loader";
import { UserContext } from "modules/User/context";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  box: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  subBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  card: {
    maxWidth: 500,
    padding: theme.spacing(1, 2),
    margin: theme.spacing(0, "auto", 8),
  },
  content: {
    minWidth: 250,
    padding: theme.spacing(4, 0),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",

    position: "relative",
    [theme.breakpoints.up("md")]: {
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
      border: `1px solid ${theme.app.palette.shadow.secondary}`,
      borderRight: "none",
    },
  },
  header: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    padding: theme.spacing(4, 7),
  },
  features: {
    padding: theme.spacing(2, 0),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      borderTopRightRadius: 5,
      borderBottomRightRadius: 5,
      border: `1px solid ${theme.app.palette.shadow.secondary}`,
      borderLeftColor: theme.app.palette.shadow.primary,
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  action: {
    width: "100%",
    padding: theme.spacing(1, 6, 2),
  },
  discountChip: {
    display: "inline-flex",
    borderRadius: 10,
    padding: theme.spacing(0, 2),
    textTransform: "uppercase",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.light,
    letterSpacing: ".05em",
    lineHeight: "1.2rem",
    fontWeight: 700,
    fontSize: ".65rem",
    marginBottom: theme.spacing(2),
  },
  supplyBox: {
    top: -10,
    left: 0,
    right: 0,
    position: "absolute",
  },
  supplyContent: {
    justifyContent: "center",
    display: "flex",
  },
  supplyChip: {
    display: "inline-flex",
    borderRadius: 10,
    padding: theme.spacing(0, 3),
    textTransform: "uppercase",
    backgroundColor: theme.palette.success.light,
    color: theme.palette.common.white,
    letterSpacing: ".05em",
    lineHeight: "1.25rem",
    fontWeight: 600,
    fontSize: ".7rem",
  },
  priceBox: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    marginBottom: theme.spacing(3.5),
    gap: 3,
  },
  priceNumber: {
    lineHeight: 0.85,
    fontWeight: 600,
    fontSize: "1.85rem",
  },

  priceBefore: {
    textDecoration: "line-through",
    color: theme.app.palette.action.color,
  },
  paymentSub: {
    color: theme.app.palette.action.color,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  avatar: {
    backgroundColor: lighten(theme.palette.success.light, 0.9),
  },
  check: {
    color: theme.palette.success.light,
  },
  currency: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: theme.spacing(3),
  },
  underline: {
    textDecoration: "underline",
  },
}));

interface PaymentBoxProps {
  accountId: number;
  checkoutUrls: ICheckoutUrls;
  skipPaymentDialog?: boolean;
}

const PaymentBox = ({
  accountId,
  checkoutUrls,
  skipPaymentDialog,
}: PaymentBoxProps): React.ReactElement => {
  const classes = useStyles();
  const queryClient = useQueryClient();

  const {
    user: { id: userId, frontend_state },
  } = React.useContext(UserContext);
  const customPrice: string = get(frontend_state, "prices.0");

  const [currency, setCurrency] = React.useState<Currencies>(Currencies.usd);

  const handleChange = (newCurrency: Currencies) => {
    setCurrency(newCurrency);
  };

  const fetchAccountSubscription = async () => {
    try {
      const { data } = await PaymentService.fetchAccountSubscription(accountId);
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data: dataAccountSubscription } = useQuery(
    [AccountQuery.account_subscription, accountId],
    () => fetchAccountSubscription(),
    {
      keepPreviousData: true,
    }
  );

  const fetchSubscription = async () => {
    try {
      const { data } = await PaymentService.fetchAllUserSubscriptions();
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };
  const { data: dataAccountSubscriptions } = useQuery(
    ["subscriptions", userId],
    () => fetchSubscription(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: !!userId,
    }
  );

  const fetchCustomers = async () => {
    try {
      const { data } = await PaymentService.fetchCustomers();
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data: dataCustomers } = useQuery(
    ["customers"],
    () => fetchCustomers(),
    {
      keepPreviousData: true,
    }
  );

  const fetchPrices = async () => {
    try {
      const { data } = await PaymentService.fetchPrices();
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data: dataPrices } = useQuery(
    ["prices", "all"],
    () => fetchPrices(),
    {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const existingCustomer: ICustomer | undefined = get(
    dataCustomers,
    "results[0]"
  );

  React.useEffect(() => {
    if (existingCustomer?.currency) {
      setCurrency(existingCustomer.currency);
      return;
    }

    const subscriptionCurrency = get(
      dataAccountSubscription,
      "subscriptions[0].data.currency"
    );

    if (subscriptionCurrency) {
      setCurrency(subscriptionCurrency);
    }
  }, [currency, dataAccountSubscription, existingCustomer]);

  const fullPriceObj = dataPrices?.results.find(
    (p) =>
      p.data.nickname === ConfigConstant.PRICE.FULL.NICKNAME &&
      p.data.currency === currency &&
      p.data.recurring.interval === RecurringInterval.month
  );

  const customPriceObj = dataPrices?.results.find((p) => p.id === customPrice);

  // if subscription exists = no trial
  const isTrialAvailable = isUserTrialAvailable(
    dataAccountSubscriptions?.results
  );

  const dataSubscriptions: ISubscriptions | undefined =
    queryClient.getQueryData(["account-subscription", accountId]);

  const activeSubscription = hasActiveSubscription(dataSubscriptions?.results);

  const mutateOnClick = useMutation(
    (price: ICheckoutSessionFormValues["price"]) =>
      PaymentService.createSubscriptionSession({
        price,
        account: accountId,
        ...checkoutUrls,
      }),
    {
      onSuccess: async (response) => {
        const stripe = await stripePromise;
        stripe?.redirectToCheckout({
          sessionId: response.data.id,
        });
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  React.useEffect(() => {
    console.log("skipPaymentDialog", skipPaymentDialog);
    console.log("fullPriceObj", fullPriceObj);
    if (skipPaymentDialog && fullPriceObj && fullPriceObj?.id) {
      mutateOnClick.mutate(fullPriceObj.id as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skipPaymentDialog, fullPriceObj]);

  if (!fullPriceObj) {
    return <Loader />;
  }

  const contentSuccess = (
    <Card className={classes.card}>
      <CardHeader
        avatar={
          <Avatar className={classes.avatar}>
            <CheckCircleIcon className={classes.check} />
          </Avatar>
        }
        title="Thanks for subscribing."
        titleTypographyProps={{
          paragraph: true,
          variant: "h6",
        }}
        subheader="Your subscription is active now and you can proceed in connecting your LinkedIn account."
        subheaderTypographyProps={{
          color: "textSecondary",
          variant: "body2",
        }}
      />
    </Card>
  );

  const fullPrice = PaymentUtils.formatStripeUnitPrice(
    fullPriceObj.data.unit_amount
  );
  const customPriceValue =
    !!customPriceObj &&
    PaymentUtils.formatStripeUnitPrice(customPriceObj?.data.unit_amount);

  const priceObj = customPriceObj || fullPriceObj;
  const price = customPriceValue || fullPrice;

  const priceWithDiscount = PaymentUtils.calculatePriceWithDiscount(
    priceObj.data.unit_amount,
    currency,
    priceObj.data.recurring,
    existingCustomer?.discount?.coupon
  );

  const contentCheckout = (
    <>
      <div className={classes.root}>
        <div>
          {!existingCustomer?.currency && (
            <>
              <div className={classes.currency}>
                <ButtonGroupFilter
                  id="filter"
                  options={[
                    {
                      id: "usd",
                      name: "USD",
                      query: {},
                    },
                    {
                      id: "eur",
                      name: "EUR",
                      query: {},
                    },
                  ]}
                  handleOnChange={handleChange}
                />
              </div>
            </>
          )}
          <div className={classes.box}>
            <div className={classes.content}>
              <div className={classes.header}>
                {!!existingCustomer?.discount && (
                  <div className={classes.discountChip}>
                    {PaymentUtils.formatCouponDiscount(
                      existingCustomer?.discount?.coupon
                    )}{" "}
                    off
                  </div>
                )}
                <Typography color="action.color" variant="subtitle1" mb={2.5}>
                  {!!isTrialAvailable
                    ? `Try ${ConfigConstant.APP_NAME} seat`
                    : `${ConfigConstant.APP_NAME} seat`}
                </Typography>

                <div className={classes.priceBox}>
                  <div className={classes.priceNumber}>
                    {!!isTrialAvailable ? <>7 days free</> : priceWithDiscount}
                  </div>
                  {!!existingCustomer?.discount && (
                    <div className={classes.priceBefore}>
                      {CurrencySign[priceObj.data.currency]}
                      {price}
                    </div>
                  )}
                </div>

                <Typography variant="body2" color="action.color">
                  {!!isTrialAvailable
                    ? `Then ${priceWithDiscount} seat / month`
                    : `seat / ${
                        priceObj.data.recurring.interval_count === 1
                          ? ""
                          : priceObj.data.recurring.interval_count
                      } month`}
                </Typography>
              </div>

              <div className={classes.action}>
                <Button
                  onClick={() => mutateOnClick.mutate(priceObj.id)} // pass selected price_id
                  type="button"
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  {isTrialAvailable ? "Continue" : "Continue to checkout"}
                </Button>
              </div>
            </div>
            <div className={classes.features}>
              <FeatureList />
            </div>
          </div>
        </div>
      </div>
      <div className={classes.subBox}>
        <Typography
          variant="caption"
          className={classes.paymentSub}
          component="span"
          sx={{ mr: 0.5 }}
        >
          <Lock fontSize="inherit" sx={{ mr: 0.5 }} />
          Secure payments by Stripe
        </Typography>
        {!!isTrialAvailable && !existingCustomer && (
          <>
            <Typography
              variant="caption"
              className={classes.paymentSub}
              component="span"
              sx={{ mr: 0.5 }}
            >
              -
            </Typography>
            <Tooltip
              title={
                <>
                  We ask for payment details to prevent fake accounts from
                  abusing LinkedIn automation, to ruin it for all of us. <br />
                  <br />
                  With your trial, you also get a dedicated IP address in your
                  country to mimic human behavior, so LinkedIn can't detect any
                  automation and your account is safe.
                </>
              }
              arrow
              placement="top"
            >
              <Typography
                variant="caption"
                className={clsx(classes.paymentSub, classes.underline)}
                component="span"
              >
                Why we ask for payment details?
              </Typography>
            </Tooltip>
          </>
        )}
      </div>
    </>
  );

  return activeSubscription ? contentSuccess : contentCheckout;
};

export default PaymentBox;
