import CloseIcon from "@mui/icons-material/Close";
import {
  Container,
  darken,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tab,
  TabProps,
  Tabs,
  useMediaQuery
} from "@mui/material";
import Box from "@mui/material/Box";
import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles, styled, useTheme } from "@mui/styles";
import { TabIndex, TabLabels } from "core/models";
import RouterConstants from "core/routes/constants";
import RouterUtils from "core/routes/utils";
import { forceStyle } from "core/utils/commonHandler";
import { AccountContext } from "modules/Account/context";
import { AccountTabs } from "modules/Account/models";
import { MyNetworkTabs } from "modules/Person/models";
import React from "react";
import { Link, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => {
  const {
    constants: { menuBarHeight },
    palette,
  } = theme.app;
  return createStyles({
    menuBarSpacer: {
      height: menuBarHeight,
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    menuBar: {
      flexGrow: 1,
      backgroundColor: theme.palette.common.white,
      boxShadow: `inset 0 -1px ${palette.shadow.secondary}`,
      height: menuBarHeight,
    },
  });
});

const AntTabs = styled(Tabs)(({ theme }) => ({
  borderBottom: "none",
  minHeight: 0,
  height: theme.app.constants.menuBarHeight,
  display: "flex",
  alignItems: "center",
  "& .MuiTabs-indicator": {
    display: "none",
  },
}));

interface AntTabProps extends TabProps {
  to: string;
}

const AntTab = styled((props: AntTabProps) => (
  <Tab LinkComponent={Link} disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  minHeight: 20,
  minWidth: 0,
  [theme.breakpoints.up("sm")]: {
    minWidth: 0,
  },
  lineHeight: "20px",
  fontWeight: theme.typography.fontWeightMedium,
  marginRight: theme.spacing(1),
  color: theme.palette.text.primary,
  padding: theme.spacing(0.5, 2.5),
  borderRadius: 40,
  transition: theme.transitions.create("all", {
    duration: theme.transitions.duration.shorter,
  }),
  "&:hover": {
    backgroundColor: darken(theme.palette.background.default, 0.05),
  },
  "&.Mui-selected": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));

function createData(
  index: TabIndex,
  name: TabLabels,
  path: string,
  rootPath: string
) {
  return { index, name, path, rootPath };
}

const createMainItems = () => [
  createData(
    TabIndex.HOME,
    TabLabels.HOME,
    RouterConstants.ROOT,
    RouterConstants.ROOT
  ),
  createData(
    TabIndex.CAMPAIGNS,
    TabLabels.CAMPAIGNS,
    RouterConstants.CAMPAIGN.ROOT,
    RouterConstants.CAMPAIGN.ROOT
  ),
  createData(
    TabIndex.INBOX,
    TabLabels.INBOX,
    RouterConstants.INBOX.ALL,
    RouterConstants.INBOX.ALL
  ),
  createData(
    TabIndex.MY_NETWORK,
    TabLabels.MY_NETWORK,
    RouterConstants.MY_NETWORK.all(MyNetworkTabs.contacts),
    RouterConstants.MY_NETWORK.ROOT
  ),
  createData(
    TabIndex.SEARCH,
    TabLabels.SEARCH,
    RouterConstants.SEARCH.ALL,
    RouterConstants.SEARCH.ALL
  ),
  createData(
    TabIndex.TEMPLATES,
    TabLabels.TEMPLATES,
    RouterConstants.SEQUENCE.ALL,
    RouterConstants.SEQUENCE.ALL
  ),
];

const mainItems = createMainItems();

const createSideItems = (accountId: number) => [
  createData(
    TabIndex.ACCOUNT_SETTINGS,
    TabLabels.ACCOUNT_SETTINGS,
    RouterConstants.ACCOUNT.detail(accountId, AccountTabs.limits),
    RouterConstants.ACCOUNT.ROOT
  ),
  createData(
    TabIndex.INTEGRATIONS,
    TabLabels.INTEGRATIONS,
    RouterConstants.INTEGRATION.ROOT,
    RouterConstants.INTEGRATION.ROOT
  ),
  createData(
    TabIndex.TEAM,
    TabLabels.TEAM,
    RouterConstants.TEAM.ROOT,
    RouterConstants.TEAM.ALL
  ),
];

interface NavTabsProps {
  drawerMobileOpen: boolean;
  handleDrawerMobileToggle: () => void;
}

const NavTabs = ({
  drawerMobileOpen,
  handleDrawerMobileToggle,
}: NavTabsProps): React.ReactElement => {
  const classes = useStyles();
  const location = useLocation();
  const theme = useTheme();

  const [container, setContainer] = React.useState<
    (() => HTMLElement) | undefined
  >(undefined);
  const desktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));

  const {
    account: { id: accountId },
  } = React.useContext(AccountContext);

  const sideItems = createSideItems(accountId);

  const checkIfCurrentRoute = (p: string) =>
    RouterUtils.isCurrentRoute(location.pathname, p);

  React.useEffect(() => {
    setContainer(window !== undefined ? () => window.document.body : undefined);
  }, []);

  return desktop ? (
    <Box className={classes.menuBar}>
      <Container maxWidth="xl">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          data-cy="topbar-menu"
        >
          <AntTabs aria-label="ant example">
            {mainItems.map(({ rootPath, path, name, index }) => {
              const selected = !!checkIfCurrentRoute(rootPath);
              return (
                <AntTab
                  label={name}
                  to={path}
                  key={name}
                  {...(selected && {
                    sx: {
                      backgroundColor: forceStyle(theme.palette.primary.main),
                      color: forceStyle(theme.palette.common.white),
                    },
                  })}
                />
              );
            })}
          </AntTabs>
          <AntTabs aria-label="ant example">
            {sideItems.map(({ rootPath, path, name, index }) => {
              const selected = !!checkIfCurrentRoute(rootPath);
              return (
                <AntTab
                  label={name}
                  to={path}
                  key={name}
                  {...(selected && {
                    sx: {
                      backgroundColor: forceStyle(theme.palette.primary.main),
                      color: forceStyle(theme.palette.common.white),
                    },
                  })}
                />
              );
            })}
          </AntTabs>
        </Box>
      </Container>
    </Box>
  ) : (
    <Drawer
      container={container}
      variant="temporary"
      open={drawerMobileOpen}
      onClose={handleDrawerMobileToggle}
      PaperProps={{
        sx: {
          boxSizing: "border-box",
          width: theme.app.constants.drawerWidth,
          borderRight: 0,
        },
      }}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      <Box my={4}>
        {!desktop && (
          <IconButton
            size="small"
            aria-label="close drawer"
            onClick={handleDrawerMobileToggle}
            sx={{
              background: theme.palette.background.default,
              margin: theme.spacing(4, 3, 4),
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
        <List>
          {[...mainItems, ...sideItems].map(
            ({ rootPath, path, name, index }) => {
              return (
                <Link to={path} key={name}>
                  <ListItem button onClick={handleDrawerMobileToggle}>
                    <ListItemText
                      primary={name}
                      primaryTypographyProps={{
                        variant: "body2",
                        color: "textSecondary",
                      }}
                    />
                  </ListItem>
                </Link>
              );
            }
          )}
        </List>
      </Box>
    </Drawer>
  );
};

export default NavTabs;
