import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import HelperInfo, { HelperInfoProps } from "./HelperInfo";

interface DialogHeaderProps {
  title: string | React.ReactElement;
  onHandleClose?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  helperProps?: HelperInfoProps;
  previewComponent?: React.ReactElement;
}

const DialogHeader = ({
  title,
  onHandleClose,
  helperProps,
  previewComponent,
}: DialogHeaderProps): React.ReactElement => (
  <DialogTitle>
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Grid container justifyContent="space-between" alignItems="baseline">
          <Typography variant="h6" color="textPrimary">
            {title}
          </Typography>
          {/* {!!helperProps && (
            <Grid item>
              <HelperInfo {...helperProps} />
            </Grid>
          )} */}
        </Grid>
      </Grid>
      <Grid item>
        <Grid container justifyContent="space-between" alignItems="center">
          {!!previewComponent && <Grid item>{previewComponent}</Grid>}
          {!!onHandleClose && (
            <Grid item>
              <IconButton
                aria-label="settings"
                onClick={onHandleClose}
                size="large"
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  </DialogTitle>
);

DialogHeader.defaultProps = {
  helperProps: undefined,
  onHandleClose: undefined,
  previewComponent: undefined,
};

export default DialogHeader;
