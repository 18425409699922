import { loadStripe } from "@stripe/stripe-js";
import ConfigConstant from "core/constants/ConfigConstant";
import {
  IAccountSubscription,
  ISubscription,
  SubscriptionStatus,
} from "modules/Payment/models";

// Stripe.js will not be loaded until `loadStripe` is called
const stripePromise = loadStripe(ConfigConstant.STRIPE_PUB_KEY as string);

const hasActiveSubscription = (
  arr: ISubscription[] | undefined
): ISubscription | undefined => {
  if (!arr) {
    return undefined;
  }
  return arr.find(
    (sub) =>
      sub.status === SubscriptionStatus.active ||
      sub.status === SubscriptionStatus.trialing ||
      sub.status === SubscriptionStatus.incomplete
  );
};

const isUserTrialAvailable = (arr: IAccountSubscription[] | undefined) => {
  if (!arr) {
    return false;
  }

  return !arr.some((sub) => sub.subscriptions.length > 0);
};

export { stripePromise, isUserTrialAvailable, hasActiveSubscription };
