import React from "react";
import { useQuery } from "react-query";
import SearchService from "modules/Search/services";
import { ISearch, SearchStatusMatchToVariant } from "modules/Search/models";
import { SEARCH_STRATEGY_MAX_RESULTS } from "modules/Search/constants";
import ProgressBar, {
  BaseProgressBarProps,
} from "ui-kit/components/ProgressBar";

interface SearchProgressProps extends BaseProgressBarProps {
  search: ISearch;
}

const createTotalObj = (
  totalCount: number | undefined,
  totalExtraLabel: string | undefined
) => ({
  totalCount,
  totalExtraLabel,
});

const getTotalObj = (
  status: ISearch["status"],
  strategy: ISearch["strategy"],
  totalCount: number | undefined,
  strategyTotal: number | undefined
) => {
  if (status === SearchStatusMatchToVariant.processed) {
    return createTotalObj(totalCount, undefined);
  }
  if (strategyTotal) {
    return createTotalObj(strategyTotal, undefined);
  }
  if (SEARCH_STRATEGY_MAX_RESULTS[strategy]) {
    return createTotalObj(SEARCH_STRATEGY_MAX_RESULTS[strategy], "up to ");
  }
  return createTotalObj(totalCount, undefined);
};

const SearchProgress = ({
  search,
  ...rest
}: SearchProgressProps): React.ReactElement => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { id: searchId, strategy, status, strategy_data } = search;

  const fetchSearchResultsCount = async () => {
    try {
      const { data } = await SearchService.fetchCountSearchResults(searchId);
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data: dataCount } = useQuery(
    ["search-results", { total: "count" }, searchId],
    () => fetchSearchResultsCount(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: !!searchId,
    }
  );

  const fetchSearchResultsTotal = async () => {
    try {
      const { data } = await SearchService.fetchTotalSearchResults(searchId);
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data: dataTotal } = useQuery(
    ["search-results", { total: "total" }, searchId],
    () => fetchSearchResultsTotal(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: !!searchId,
    }
  );

  const { totalCount, totalExtraLabel } = getTotalObj(
    status,
    strategy,
    dataTotal?.count,
    strategy_data?.total
  );
  const isLoading = !(
    dataCount?.count !== undefined && totalCount !== undefined
  );

  const maxTotal = SEARCH_STRATEGY_MAX_RESULTS[search.strategy];

  return (
    <ProgressBar
      count={dataCount?.count}
      total={Number(maxTotal) < Number(totalCount) ? maxTotal : totalCount}
      totalExtraLabel={totalExtraLabel}
      isLoading={isLoading}
      {...rest}
    />
  );
};

export default SearchProgress;
