import React from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import { Theme } from "@mui/material/styles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton";
import RouterConstants from "core/routes/constants";
import {
  formatDateTimeToSmartTime,
  formatTimeZoneName,
  getCurrentTimezoneDate,
  WEEK_DAY_ARR,
} from "core/utils/dateHandler";
import AccountService from "modules/Account/services";
import { AccountQuery, AccountTabs } from "modules/Account/models";
import { AccountContext } from "modules/Account/context";
import { getValueWorkHours } from "modules/Account/utils";
import { UserContext } from "modules/User/context";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    padding: theme.spacing(5, 0.5),
  },
  action: {
    padding: theme.spacing(2, 0.5),
  },
  leftColumn: {
    [theme.breakpoints.up("md")]: {
      borderRight: `1px solid ${theme.app.palette.shadow.primary}`,
      marginRight: theme.spacing(0),
    },
    [theme.breakpoints.down("md")]: {
      borderBottom: `1px solid ${theme.app.palette.shadow.primary}`,
    },
  },
  rightColumn: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(3),
    },
  },
  middleColumn: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(3),
      borderRight: `1px solid ${theme.app.palette.shadow.primary}`,
    },
    [theme.breakpoints.down("md")]: {
      borderBottom: `1px solid ${theme.app.palette.shadow.primary}`,
    },
  },
  actionBtn: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  subtext: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "99%",
  },
}));

const ReportWorkHours = (): React.ReactElement | null => {
  const classes = useStyles();

  const { user } = React.useContext(UserContext);
  const { account } = React.useContext(AccountContext);
  const { id: accountId } = account;

  const fetchIsWorkHour = async () => {
    try {
      const { data } = await AccountService.fetchAccountIsOnline(accountId);
      return data;
    } catch (err) {
      throw new Error(String(err));
    }
  };
  const { data } = useQuery(
    [AccountQuery.is_online, accountId],
    () => fetchIsWorkHour(),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!accountId && !user.anonymous,
    }
  );

  const { data: accountData } = useQuery(
    [AccountQuery.account, accountId],
    async () => {
      try {
        const response = await AccountService.fetchAccount(accountId);
        return response.data;
      } catch (err) {
        throw new Error(String(err));
      }
    },
    {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!accountId && !user.anonymous,
    }
  );

  if (!account.time_zone || !accountData?.work_times) {
    return null;
  }

  // Need to minus 1, because WEEK_DAY_ARR counts from 0 and dayjs counts from 1
  const currentDay = getCurrentTimezoneDate().get("day");
  const dayName = WEEK_DAY_ARR[currentDay];
  const currentWorkDay = accountData?.work_times[dayName];

  return (
    <div>
      <Grid container>
        <Grid item xs={12} md={4} className={classes.leftColumn}>
          <div className={classes.content}>
            <Typography variant="body2" color="textSecondary" sx={{ mb: 0.5 }}>
              Active hours
            </Typography>
            <Typography variant="subtitle1" color="textPrimary">
              {account ? (
                getValueWorkHours(currentWorkDay)
              ) : (
                <Skeleton width={100} />
              )}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} md={4} className={classes.middleColumn}>
          <div className={classes.content}>
            <Typography variant="body2" color="textSecondary" sx={{ mb: 0.5 }}>
              Last synced
            </Typography>
            <Typography variant="subtitle1" color="textPrimary">
              {data?.session_open_at ? (
                formatDateTimeToSmartTime(data?.session_open_at)
              ) : (
                <Skeleton width={100} />
              )}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} md={4} className={classes.rightColumn}>
          <div className={classes.content}>
            <Typography variant="body2" color="textSecondary" sx={{ mb: 0.5 }}>
              Timezone
            </Typography>
            <Typography
              variant="subtitle1"
              color="textPrimary"
              className={classes.subtext}
            >
              {account ? (
                formatTimeZoneName(account.time_zone)
              ) : (
                <Skeleton width={100} />
              )}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Divider light />
      <div className={classes.action}>
        <Button
          component={Link}
          to={RouterConstants.ACCOUNT.detail(
            accountId,
            AccountTabs["schedule"]
          )}
          sx={{ mx: -5 }}
          size="small"
          variant="text"
          color="primary"
          endIcon={<ArrowForwardIcon />}
          className={classes.actionBtn}
        >
          Edit active schedule
        </Button>
      </div>
      <Divider />
    </div>
  );
};

export default ReportWorkHours;
